@font-face {
    font-family: "Sanz-Reg";
    src: local("Sanzettica-RegCond"), 
    url("../fonts/Sanzettica-RegCond.ttf") format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Thin";
    src: local("Sanzettica-ThinCond"), 
    url("../fonts/Sanzettica-ThinCond.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Thin-Reg";
    src: local("Sanzettica-Thin"), 
    url("../fonts/Sanzettica-Thin.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Thin-Expd";
    src: local("Sanzettica-ThinExpd"), 
    url("../fonts/Sanzettica-ThinExpd.ttf") format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Reg-Expd";
    src: local("Sanzettica-RegExpd"), 
    url("../fonts/Sanzettica-RegExpd.ttf") format("truetype");
   }
  

   @font-face {
    font-family: "Sanz-Thin-Italic";
    src: local("Sanzettica-ThinItalic"), 
    url("../fonts/Sanzettica-ThinItalic.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Bold";
    src: local("Sanzettica-Bold"), 
    url("../fonts/Sanzettica-BoldCond.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Heavy";
    src: local("Sanzettica-Heavy"), 
    url("../fonts/Sanzettica-HeavyCond.ttf") format("truetype");
   }

   @font-face {
    font-family: "Sanz-Black";
    src: local("Sanzettica-Black"), 
    url("../fonts/Sanzettica-BlackCond.ttf") format("truetype");
   }

   .slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  


   @-webkit-keyframes WelcomePageGradient {
    0%{background-position:0% 47%}
    50%{background-position:100% 54%}
    100%{background-position:0% 47%}
  }
  @-moz-keyframes WelcomePageGradient {
    0%{background-position:0% 47%}
    50%{background-position:100% 54%}
    100%{background-position:0% 47%}
  }
  @keyframes WelcomePageGradient {
    0%{background-position:0% 47%}
    50%{background-position:100% 54%}
    100%{background-position:0% 47%}
  }



  @-webkit-keyframes OpacityInOut {
    0%{opacity: 100%}
    10%{opacity: 100%}
    70%{opacity: 0%}
    100%{opacity: 100%;}
  }
  @-moz-keyframes OpacityInOut {
    0%{opacity: 100%}
    10%{opacity: 100%}
    70% {opacity: 0%}
    100%{opacity: 100%;}
  }
  @keyframes OpacityInOut {
    0%{opacity: 100%}
    10%{opacity: 100%}
    70%{opacity: 0%}
    100%{opacity: 100%;}
  }
    

   * {
    margin: 0;
    
   }

   video {
    position: relative;
    width: 100%;
    aspect-ratio: 4 /5 ;
    filter: saturate(1.0) brightness(1.00);
    opacity: 1;

}

span.reg {
    font-size: 9px;
    text-align: top;
    position: absolute;
    margin-left: 5px;
    top: -9px;
}

span.reg-tm {
    font-size: 12px;
    text-align: top;
    position: absolute;
    margin-left: 0px;
    top: 4.5px;
}

   *::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  a {text-decoration: none;}

  .main {
    box-shadow: inset -7px 0 7px -7px rgba(0,0,0,0.4);
  }
  

.main-page {
    color: black;
    width: 100%;
    padding-bottom: 200px;

}

.main-title {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    color: black;
    text-transform: uppercase;
    letter-spacing: .6px;
    font-size: 10.5px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 20px;
    width: 100%;
    overflow: hidden;
    background: white;
    padding-bottom: 10px;
    line-height: 1.4em;
    color: rgb(27, 27, 27);

}

.title2.c.studio {
      
    text-align: center;
    position: relative;
    z-index: 9999;
    color: black;
    font-weight: 200;
    font-size: 22px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.237);
    letter-spacing: 1.5px;
    padding-top: 7px;
    vertical-align: bottom;
    font-family: "Sanz-Reg-Expd", sans-serif;
}

.title4.wht.c.studio {
    position: absolute;
    z-index: 99;
    font-family: "Sanz-Reg-Expd", sans-serif;
    color: white;
    font-weight: 300;
    font-size: 1.35em;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.492);
    letter-spacing: 1.5px;
    padding-right: 0px;
    top: 96%; transform: translate(0, -90%);
    padding-bottom: 0px;

}

.title3 {
      
    text-align: center;
    position: relative;

    z-index: 9999;
    font-family: "Sanz-Reg-Expd", sans-serif;
    color: black;
    font-weight: 500;
    font-size: 8.5px;
    text-shadow: none;
    text-shadow: .5px .5px 0px rgba(0, 0, 0, 0.473);
    margin-top: 10px;
    letter-spacing: 1.5px;
    line-height: 1.2em;
    vertical-align: middle;
}

span.moving-grad2 {
    
    font-family: "Sanz-Reg-Expd", sans-serif;
    background: linear-gradient(to left,#772dac, #f43ea8);
    margin-left: 0px;
    letter-spacing: 0px;
    background-clip: text;
    font-weight: 700;
    -webkit-backgrund-clip: text;
    position: relative;
    color: transparent;
    font-size: 38px;
    letter-spacing: 1px;
    vertical-align: middle;
    padding: 0px;
    margin-right: -6px;
    text-shadow: 2px 2px 5px rgba(8, 8, 8, 0.156);
}     

span.moving-grad4 {
    
    font-family: "Sanz-Reg-Expd", sans-serif;
    background: linear-gradient(to left,#772dac, #f43ea8);
    margin-left: 0px;
    letter-spacing: 0px;
    background-clip: text;
    font-weight: 900;
    -webkit-backgrund-clip: text;
    position: relative;
    color: transparent;
    font-size: 1.2em;
    letter-spacing: 1px;
    vertical-align: middle;
    padding: 0px;
    text-shadow: 2px 2px 5px rgba(8, 8, 8, 0.156);
}     

.gradient-underline {
    width: 100%;
    margin-top: 0px;
    height: 2.5px; 
    margin-bottom: 7px;
    background: linear-gradient(to left, #2E2F2F, #2E2F2F, #454747, #454747, #E7EBEC, #454747, #2E2F2F, #181919);
    background: linear-gradient(to left,transparent, #772dac, #f43ea8);
}

.main-title.c {
    display: block;
    position: relative;
    text-align: center;
}

.navbar-row-a {
    width: 100%;
    text-align: center;
}

a.top-links {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    background: linear-gradient(to left, #020202, #1a1a1a, #181818, #141414, #ffffff, #1a1a1a, #151515, #161616);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-size: 400% 400%;
    background-clip: text;
    -webkit-background-clip: text;
    letter-spacing: -1px;
    color: rgba(0, 0, 0, 0);
    text-transform: none;
    padding: .6rem;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-animation: WelcomePageGradient 10s ease infinite;
    -moz-animation: WelcomePageGradient 10s ease infinite;
    animation: WelcomePageGradient 10s ease infinite;
    font-size: .875rem;
    line-height: 1.15rem;
    letter-spacing: -.4px;
}

.title5 {
    display: block;
    position: relative;
    margin: 0 auto;
    text-align: right;
    font-weight: 800;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-size: 400% 400%;
    background-clip: text;
    -webkit-background-clip: text;
    letter-spacing: -1px;
    color: black;
    text-transform: none;
    padding: 0rem;
    padding-top: 0px;
    padding-bottom: 0;

    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.132);
    font-size: 1.175rem;
    line-height: 1.15rem;
    letter-spacing: -.4px;

}

.title6 {
    display: block;
    position: relative;
    margin: 0 auto;
    text-align: center;
    font-weight: 800;
    background: linear-gradient(to left, #020202, #1a1a1a, #181818, #141414, #ffffff, #1a1a1a, #151515, #161616);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-size: 400% 400%;
    background-clip: text;
    -webkit-background-clip: text;
    letter-spacing: -1px;
    color: rgba(0, 0, 0, 0);
    text-transform: none;
    padding: .6rem;
    padding-top: 10px;
    padding-bottom: 0;
    -webkit-animation: WelcomePageGradient 10s ease infinite;
    -moz-animation: WelcomePageGradient 10s ease infinite;
    animation: WelcomePageGradient 10s ease infinite;
    font-size: 1.575rem;
    line-height: 1.75rem;
    letter-spacing: -.4px;
}

.curated-artists div.title6 {
    font-size: 16px;
    padding-top: 10px;
    margin-bottom: -10px;
}

.no-pad-top {
    padding-top: 0px;
}

.action-button2 {
    display: inline-block;
    position: relative;
    z-index: 900000;
    background-color: transparent;
    width: 100px;
    height: auto;
    margin: 0px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    padding-top: 22px;
    padding-bottom: 18px;
    margin-top: -3px;
    text-align: center;
    text-transform: uppercase;
    font-size: 9px;
    -webkit-appearance: none;
    transition: .4s;
      border-image: linear-gradient(to right, #005e7f, #61b6cd);
      border-radius: 50px;
      background-size: 100%;
      background: rgb(0, 0, 0);
      border: 2px transparent;
      border-radius: 30px;
      background-origin: border-box;
      background-clip: content-box, border-box;
      vertical-align: middle;
      font-family: "Sanz-Thin", sans-serif;
      margin-left: 12px;
    }
    
    
    
    .action-button2::after {
    z-index: -100000;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    transform: translateZ(-1px);
    background: linear-gradient(306deg, black, #772dac, #772dac, rgb(22, 22, 22), #f43ea8, #f43ea8);
    background-color: #f6f6f6;
    background-color: #f9fcff;
    
    background: #772dac;  /* fallback for old browsers */

    background: linear-gradient(306deg, black, #772dac, #772dac, #772dac, #f43ea8, #f43ea8, #f43ea8);
    background-size: 400% 400%;

    -webkit-animation: WelcomePageGradient 10s ease infinite;
    -moz-animation: WelcomePageGradient 10s ease infinite;
    animation: WelcomePageGradient 10s ease infinite;
    font-family: "Sanz-Thin", sans-serif;
    }
    
    
    .action-button-text2 {
    display: block;
    position: absolute;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: transparent;
    vertical-align: middle;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    font-weight: 700;
    letter-spacing: .8px;
    width: 100%;
    letter-spacing: 0;
    font-family: "Sanz-Heavy", sans-serif;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.27);
    font-size: 1.05rem;
    line-height: 1.15rem;
    letter-spacing: -.2px;
    text-transform: none;
    }

    .navbar-row {
        background: linear-gradient(to bottom, #772dac27, transparent);
        margin-top: 5px;
        width: 100%;
        white-space: nowrap;
        overflow-x: scroll;

    }

    .navbar-row-inside {
        padding-left: 10px;
        padding-right: 20px;
        position: relative;
        text-align: center;
        display: block;
    }

    a.top-links2 {
        margin: 0 auto;
        font-weight: 700;
        font-size: .7em;
        font-family: "Sanz-Reg", sans-serif;
        margin-left: 0px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        letter-spacing: -1px;
        color: rgb(0, 0, 0);
        text-transform: none;
        padding: .6rem;
        padding-top: 0;
        display: inline-block;
        font-size: .745rem;
        line-height: 1.15rem;
        letter-spacing: -.4px;
        text-decoration: none;
        background: white;
        margin: 10px;
        margin-right: 4px;
        margin-left: 4px;
        border-radius: 4px;
        padding: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    }

    .main-video-container {
        width: 90%;
        height: fit-content;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
        text-align: center;
        aspect-ratio: 4/5;   

    }

    .video-container {
       width: 100%;
       text-align: center;
       margin: 0 auto;
       margin-top: 0px;
       display: block;
       position: relative;
       aspect-ratio: 5/7;
       height: fit-content;
    }



    .mobile-no {
        display: none;
    }

    .video-overlay {
        z-index: 9;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, transparent, transparent, black);
        top: 0;
      
    }

    .pic-caption {
        font-weight: 300;
        text-shadow: none;
        text-align: left;
        width: 90%;
        margin: 10px auto;
        font-size: 12px;
    }

    .smaller {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 14px;
        width: 90%;
        margin: 10px auto;
        font-weight: 600;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    }


    .smaller2 {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 14px;
        width: 90%;
        margin: 10px auto;
        font-weight: 700;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    }

    .internal-button {
        padding: 10px;
        margin: 10px;
        width: 40%;
        margin: 15px auto;
        background: rgb(255, 255, 255);
        border-radius: 50px;
        box-shadow: rgba(255, 255, 255, 0.3) 0px 1px 2px 0px, rgba(255, 255, 255, 0.15) 0px 1px 3px 1px;
    }

    a.internal-link {
        color: transparent;
        font-weight: 800;
        text-decoration: none;
        padding-top: 0px;
        font-size: 1.1em;
        letter-spacing: -.1px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background:  #f43ea8;
        margin-left: 0px;
        letter-spacing: 0px;
        background-clip: text;
        -webkit-backgrund-clip: text;
        text-shadow: none;
    }

    .artist-sidebar {
        display: block;
        position: fixed;
        height: 100vh;
        top: 0;
        padding-top: 10px;
        background: white;
        z-index: 9999999;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        width: 100%;
        text-align: center;
        overflow: hidden;
        font-weight: 700;
        font-size: .85rem;
        margin-left: 0px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        letter-spacing: -1px;
        line-height: 1.4em;
        color: rgb(0, 0, 0);
        overflow-y: scroll;
    }

    .inside-artist-sidebar {
        min-height: 130vh;
        padding-bottom: 30vh;
    }

    .artist-box {
        width: 46.5%;
        margin: 1% 1%;
        padding: .4%;
        overflow: hidden;
        white-space: wrap;
        display: inline-block;
        position: relative;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        height: 270px;
        border: .5px solid #772dac;
    }

    .curated-artists {
        display: block;
        text-align: center;
        position: relative;
    }

    .artist-box4 {
        width: 46.5%;
        margin: 1% 1%;
        padding: .4%;
        overflow: hidden;
        white-space: wrap;
        display: inline-block;
        position: relative;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        height: 250px;
        border: .5px solid #772dac;
    }

    .inside-curated-artists {
        position: relative;
        display: block;
        text-align: left;
        margin: 0px auto;
        margin-bottom: 20px;
    
    }

    .artist-box-overlay {

    }

    .artist-box2 {
        height: 60vh;
    
        overflow: hidden;
        white-space: wrap;
        display: inline-block;
        position: relative;
        transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    img.artist-img {
        position: relative;
        height: 102%;
        left: 50%; transform: translate(-50%);
        top: -1%;
        z-index: 0;
    
    }

    img.artist-img2 {
        position: relative;
        height: 105%;
        animation: OpacityInOut 8s;
    
    }

    img.artist-img3 {
        position: relative;
        height: 100%;
        top: 0%;
        left: 50%; transform: translate(-50%);
        animation: OpacityInOut 7s;
    }


    .model-video-row {
        display: block;
        text-align: center;
        overflow-y: scroll;
        width: 100%;
        white-space: nowrap;
   
        padding-bottom: 20px;
    }

    .main-model-video-container {
        display: inline-block;
        position: relative;
        width: 46%;
        padding: 3px;
        margin: 3px;
        text-align: center;
        border-radius: 25px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 20px 80px -20px, rgba(0, 0, 0, 0.3) 0px 40px 30px -30px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      
    }

    .model-video-container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    
        
    }

    img.model-img {
        position: relative;
        width: 100%;
        height: auto;
        border-radius: 20px;
        mask-image: linear-gradient(-65deg,white 0%,rgba(0, 0, 0, 1.0) 10%, rgba(0, 0, 0, 1.0) 70%, rgba(255, 255, 255, 0.467) 100%);
    }

    .best-of img{
        position: absolute;
        width: 15%;
        right: 10px;
        top: 15px;
        z-index: 999999;
    }

    .model-name {
        color: white;
        position: absolute;
        left: 50%; top: 90%;
        z-index: 999999;
        transform: translate(-50%, -97%);
        font-family: "Sanz-Reg-Expd", sans-serif;
        font-family: poppins, sans-serif;
        color: white;
        font-weight: 600;
        font-size: 1.2em;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.558);
        letter-spacing: 1px;
    }

    .model-video-overlay {
        position: absolute;
        z-index:-1;
        background: linear-gradient(to left, #772dac, #772dac, transparent, #f43ea8, #f43ea8);
        background: linear-gradient(to bottom left,#d10bcd, #d10bcd, #d10bcd, transparent, #f43ea8, #f43ea8, #f43ea8, #772dac);
        background: linear-gradient(to bottom left, #f43ea8, transparent, transparent, #f43ea8, #f43ea8, #f43ea8);
        background-size: 200% 200%;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        border-radius: 23px;
        -webkit-animation: WelcomePageGradient 4s ease infinite;
        -moz-animation: WelcomePageGradient 4s ease infinite;
        animation: WelcomePageGradient 4s ease infinite;
    }

    .model-video-container i {
        position: absolute;
        top: 60%; left: 50%;
        z-index: 99;
        transform: translate(-50%, -50%);
        font-size: 3.2em;
        opacity: .9;
        color: #ffffffe4;
        -webkit-animation: WelcomePageGradient 8s ease infinite;
        -moz-animation: WelcomePageGradient 8s ease infinite;
        animation: WelcomePageGradient 8s ease infinite;
    }

 

    span.artist-title {
        font-size: .75em;
        font-weight: 400;
        right: 0;
        display: block;
        position: relative;
        font-family: poppins, sans-serif;
        text-align: left;
        line-height: 1.1em;
        vertical-align: bottom;
    }

    span.artist-title2 {
        font-size: .55em;
        font-weight: 400;
        right: 0;
        display: block;
        position: relative;
        font-family: poppins, sans-serif;
        text-align: left;
        line-height: 1em;
        vertical-align: bottom;
    }

    div.artist-title4 {
        display: block;
        position: relative;
        font-size: .65em;
        font-weight: 400;
        line-height: 1em;
        padding-top: 4px;
        padding-bottom: 4px;
        width: 90%;
        white-space: wrap;
    }

    video.artist-img {
        position: absolute;
        top: 0; left: 0;
        height: 60vh;
        z-index: -1;
     
    }

    video.artist-img2 {
        position: absolute;
        top: 0; left: 2px;
        height: 100%;
        z-index: -1;
     
    }

    video.artist-img4 {
        position: absolute;
        top: 1%; left: 0;
        height: 60vh;
        z-index: -1;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
     
    }

    .artist-name {
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding-left: 10px;
        width: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        background: linear-gradient(to right, white, white, transparent);
        text-align: left;
        font-family: poppins, sans-serif;
        font-size: 1.25em;
        vertical-align: middle;
        line-height: 1.4em;
        height:  auto;
        z-index: 999999999;
        white-space: wrap;
        letter-spacing: .1px;
    }

    .artist-name2 {
        position: absolute;
        left: 0px;
        bottom: 10px;
        padding-left: 10px;
        width: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        background: linear-gradient(to right, white, white, transparent);
        text-align: left;
        font-family: poppins, sans-serif;
        font-size: 1.35em;
        vertical-align: middle;
        line-height: 1.4em;
        z-index: 999999999;
        letter-spacing: .1px;
        font-weight: 600;
        
    }

    .artist-name3 {
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding-left: 10px;
        width: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        background: linear-gradient(to right, white, white, transparent);
        text-align: left;
        font-family: poppins, sans-serif;
        font-size: 1.15em;
        vertical-align: middle;
        line-height: 1.4em;
        z-index: 999;
        letter-spacing: .1px;
        font-weight: 600;
        
    }



    .artist-page {
        position: fixed;
        top: 0px;
        text-align: center;
        width: 100%;
        z-index: 999999999;
        background: white;
        height: 100vh;
        overflow-y: scroll;
    }

    .artist-page2 {
        position: fixed;
        top: 0;
        padding-top: 0px;
        text-align: center;
        width: 100%;
        z-index: 999999999;
        background: white;
        height: calc(100vh);
        overflow-y: scroll;
    }

    .inside-artist-page {
        position: absolute;
        padding-bottom: 10vh;
    }

    .artist-page i {
        position: fixed;
        top: 20px; right: 20px;
        opacity: .8;
        color: rgba(95, 95, 95, 0.336);
        font-size: 44px;
        z-index: 9999999;
    }

    .artist-page2 i {
        position: fixed;
        top: 20px; right: 20px;
        opacity: .8;
        color: rgba(95, 95, 95, 0.336);
        font-size: 44px;
        z-index: 9999999;
    }

    .l {
        text-align: left;
    }

    .artist-sidebar i {
        position: fixed;
        top: 20px; right: 20px;
        opacity: .8;
        color: #772dac76;
        font-size: 44px;
        z-index: 9999999;
    }

    .artist-bio {
        display: block;
        text-align: left;
        width: 90%;
        margin: 0 auto;
        font-weight: 400;
        font-family: poppins, sans-serif;
        line-height: 1.4em;
        font-size: 14px;
        margin-top: 10px;
        position: relative;
    }

    .artist-bio i {
        position: absolute;
        color: white;
        font-size: 50px;
        right: -10px;
        float: right;
        top: 175px;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.936);
        transform: rotate(180deg);
    }

    .artist-bio.bold {
        font-weight: 600;
        display: block;
    }

    .artist-bio-closed {
        text-align: left;
        width: 90%;
        margin: 0 auto;
        font-weight: 400;
        font-family: poppins, sans-serif;
        line-height: 1.4em;
        font-size: 14px;
        margin-top: 10px;
        height: 7em;
        overflow: hidden;
    }

    .read-more {
        width: 90%;
        margin: 0 auto;
        font-weight: 700;
        font-family: poppins, sans-serif;
        line-height: 1.4em;
        font-size: 14px;
        color: #772dac;
    }

    .artist-box-overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999999;
        background: linear-gradient(to right, white, transparent, transparent, white);
      
    }

    .artist-box-overlay2 {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999999;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.434), transparent, transparent, rgba(255, 255, 255, 0.522));
      
    }


    .right-btn {
        position: fixed;
        right: 15px;
        bottom: 42px;
        z-index: 99999999999999999999999;
      }
      
      .bottom-course-nav {
        height: 90px;
        background: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 999999999;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
      }

      .financing-bar {
        background: linear-gradient(to right,#d10bcd, #f43ea8, #772dac);
        background-size: 400% 400%;
        -webkit-animation: WelcomePageGradient 10s ease infinite;
        -moz-animation: WelcomePageGradient 10s ease infinite;
        animation: WelcomePageGradient 10s ease infinite;
        color: white;

    
      }


      .financing-bar p.smaller{
        text-align: center;
        font-size: 12px;
        font-family: Robotocondensed, sans-serif;
        padding-top: 6px;
        margin-left: 5px;
        width: 100%;
      }

      .financing-bar a {
        border-bottom: .5px solid white;
        text-decoration: none;
        color: white;
        margin-left: 5px;
      }

      a.learn-more {
        margin-left: 10px;

      }
      
      .left-btn {
        position: absolute;
        left: 20px;
        top: 0;
      }

      .left-words {
        font-size: 18px;
        padding-top: 8px;
        text-transform: uppercase;
        padding-left: 20px;
        line-height: 1.2em;
        padding-right: unset;
        text-align: left;
        font-family: poppins, sans-serif;
        font-weight: 600;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      }
      
      .left-words-2 {
        font-size: 10.5px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      }
      

      
      span.line {
        display: inline-block;
        height: 1px;
        position: relative;
        background-color: black;
        width: 22px;
        vertical-align: middle;
        margin-right: 4px;
       
      }
      
      span.line.white-back {
        background-color: white;
      }

      h1 {
        font-family: poppins, sans-serif;
        font-size: 1.25em;
        padding: 10px;
        padding-bottom: 0;
        font-weight: 800;
        color: #772dac;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      }

      .financing i {
        font-size: 30px;
        color: #d10bcd;
      }

      .financing .email-link i {
        font-size: inherit;
      }

      h1.smaller2  {
        font-size: 30px;
    }


      p {
        padding: 10px;
        font-family: poppins, sans-serif;
        font-size: 14px;
        line-height: 1.4em;
      }

  

      .add-page {
        padding-bottom: 100px;
      }

      .yes-box i {
        color: #772dac;
        position: relative;
        font-size: 24px;
        margin-left: 20px;
        margin-top: 5px;
      }

      img.procedure-room {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        filter: brightness(1.07);
      }

      .c {
        text-align: center;
      }

      .email-link {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background: #772dac;
        margin-left: 0px;
        letter-spacing: 0px;
        background-clip: text;
        font-weight: 700;
        -webkit-backgrund-clip: text;
        position: relative;
        color: transparent;
        font-size: 12px;
        letter-spacing: 1px;
        vertical-align: middle;
        padding: 0px;
        margin-right: -6px;
     
      }

    iframe {
        max-width: 90%;
        box-shadow: none;
        border: none;
        outline: none;
      }

   

      .hidden {display: none;}

      .work-sample-row {
        width: 100%;
        margin-top: 10px;
      
        white-space: nowrap;
        overflow: hidden;
        overflow-x: scroll;
        position: relative;
        display: block;
      }

      span.price {
        display: inline-block;
        font-weight: 300;
        padding-left: 5px;
      }

      .image-wrap {
        display: inline-block;
        position: relative;
        height: 340px;
        padding: 1px;
      }

      img.work-sample {
        height: 340px;
        width: auto;
        position: relative;
      
      }

      p.smaller {
        font-size: 13px;
        text-align: left;
      }

      p.smaller.c {
        text-align: center;
      }

      .font-14 {
        font-size: 14px;
      }

      .megcontainer {
        aspect-ratio: 2/1.4;
        overflow: hidden;
        width: 90%;
        margin: 0 auto;
        margin-top: 30px;
      }

      img.megbanner {
        width: 100%;
        margin-top: -60px;
        position: relative;
        left: 50%; transform: translate(-50%);
        z-index: -1;
        mask-image: linear-gradient(to top left,transparent 0%,rgba(0, 0, 0, 1.0) 30%, rgba(0, 0, 0, 1.0) 80%, transparent 100%);
      }


      img.megbanner3 {
        width: 90%;
        margin-top: 0px;
        margin-bottom: 0px;
        position: relative;
        left: 50%; transform: translate(-50%);
        z-index: -1;
        mask-image: linear-gradient(to bottom right,transparent 0%,rgba(0, 0, 0, 1.0) 30%, rgba(0, 0, 0, 1.0) 100%);
        
      }

      img.megbanner4 {
        width: 90%;
        margin-top: 10px;
        margin-bottom: 0px;
        position: relative;
        left: 50%; transform: translate(-50%);
        z-index: -1;
        mask-image: linear-gradient(to bottom right,transparent 0%,rgba(0, 0, 0, 1.0) 30%, rgba(0, 0, 0, 1.0) 100%);
        
      }

      img.megbanner2 {
        display: none;
      }

      .service-row {
        text-align: center;
        padding-top: 0px;
      }
      
      .service-container {
        display: inline-block;
        width: 47%;
        padding: 0; margin-top: 10px;
        vertical-align: top;
        text-align: center;
        position: relative;
        overflow: hidden;
        background: transparent;
       
      }

      .service-container video {
        width: 94%;
      
        vertical-align: middle;
        margin: 0 auto;
        margin-top: -14px;
        background: transparent;
        aspect-ratio: 4/5;
     
      }

      .service-container-overlay {
        position: absolute;
        top: 50%; left: 50%; transform: translate(-50%, -50%);
        width: 94%; height: 100%;
        background: linear-gradient(160deg, transparent, transparent, rgba(197, 80, 255, 0.672));
        background: linear-gradient(160deg, transparent, transparent, rgba(13, 13, 13, 0.672));
        z-index: 9;
       
      }

      .service-container-overlay.eye {
        position: absolute;
        top: 50%; left: 50%; transform: translate(-50%, -50%);
        width: 100%; height: 95%;
        background: linear-gradient(200deg, transparent, transparent, rgba(197, 80, 255, 0.672));
        z-index: 9;
        background: linear-gradient(160deg, transparent, transparent, rgba(13, 13, 13, 0.672));
      }

      .service-name {
        color: white;
        position: absolute;
        top: 88%; left: 50%; transform: translate(-50%, -88%);
        z-index: 99;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.558);
        font-family: roboto-condensed, sans-serif;
      }

      .rotate-180 {
        transform: rotate(180deg);
      }

      .eyeliner {
        left: 1px;
        transform: rotate(-45deg) ;
        margin-top: 5px;
        z-index: 999999;
      }

      .service-container img {
        width: 100%;
      }
      
      .footer {
        margin-top: 20px;
        padding-top: 20px;
        margin-bottom: 20px;
        background: linear-gradient(to bottom, #772dac2e, transparent);
      }

      .landing-box {
        width: 80%;
        margin: 10px auto;
        background: linear-gradient(to top left, #d10bce, white);
        
        border-radius: .375rem;
        box-shadow: 0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04);
        padding: 20px;
        padding-bottom: 30px;
      }

      .wht, .wht i{color: white;}

      .sibling {
        position: relative;
      }

      img.sibling-duo {
        width: 90%;
        margin: 10px auto;
        mask-image: linear-gradient(to bottom right,transparent 0%,rgba(0, 0, 0, 1.0) 10%, rgba(0, 0, 0, 1.0) 100%);
        
      }

      .sibling-overlay {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
      
      }

      .sibling-duo.overlay-text {
        position: absolute;
        top: 76%; left: 50%; transform: translate(-50%, -80%);
        color: white;
        z-index: 99;
        width: 80%;
        font-size: 18px;
        font-weight: 600;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.949);
        font-family: roboto-condensed, sans-serif;
   
      }

      .campaign.ppr{
        display: block;
        width: 90%;
        
        margin: 5px auto;
      
 
      }

      .campaign.ppr img {
        position: relative;
        width: 100%;
      }


      .campaign.pl img, .campaign.pr img {
        width: 100%;
      }

      .campaign div.pic-caption {
     
      }

      img.pr {
        width: 40%;
        float: right;
      }


      .photo-wrap {
        width: 100%;
        overflow-y: scroll;
        white-space: nowrap;
      }

      .photo-container {
        height: 200px;
        width: auto;
        display: inline-block;
        margin-left: 5px;
     
      }

      img.main-work-sample {
        height: 300px;
        border-radius: .75rem;
      }


      span.artist-title4 {
        white-space: wrap;
      }

      span.artist-title {
        font-size: 12px;

      }

      .artist-title4.purp {
        color: #772dac;
        font-family: roboto-condensed, sans-serif;
        font-weight: 550;
        padding-top: 8px;
        font-size: 11px;

     
      }

      .pad-40top {
        padding-top:40px;
      }



@media (min-width: 800px) {


    .landing-box {
        width: 80%;
        margin: 10px auto;
        background: linear-gradient(to top left, #d10bce, white);
        
        border-radius: .375rem;
        box-shadow: 0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04);
        padding: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
      }


    img.megbanner4 {
        width: 80%;
        vertical-align: middle;

      }

    div.pic-caption {
        padding-left: 0%;
        width: 80%;
        margin: 20px auto;
        text-align: left;
    }


      
    .megcontainer {
        aspect-ratio: 2/1.4;
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
    }


    .video-container {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        margin-top: 0px;
        display: block;
        position: relative;
        aspect-ratio: 5/7;
        min-width: 1000px;
        height: fit-content;
     }


    video.artist-img {

            position: absolute;
            top: 0; left: 50%;
            transform: translate(-50%);
            height: 60vh;
            z-index: -1;
         
    }

    .curated-artists {
       margin-top: 50px;
       width: 100%;
       display: block;
    }

    .inside-curated-artists {
        position: relative;
        display: block;
        text-align: left;
        margin: 10px auto;
        margin-bottom: 20px;
        padding-top: 80px;
    }




    .artist-box4 {
        width: 200px;
        margin: 1% 1%;
        padding: .4%;
        overflow: hidden;
        white-space: wrap;
        display: inline-block;
        position: relative;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        height: 240px;
        border: .5px solid #772dac;
    }


    .read-more {
        width: 90%;
        margin: 0 auto;
        font-weight: 700;
        font-family: poppins, sans-serif;
        line-height: 1.4em;
        font-size: 14px;
        padding-left: 5%;
        color: #772dac;
    }

    .title6 {

        font-size: 1.875rem;
        padding-bottom: 10px;
        padding-top: 10px;
        width: 100%;
    
    }

  
      img.megbanner2 {
        display: inline-block;
        width: 30%;
        margin-top: 10px;
        position: absolute;
        left: 2%; transform: none;
        z-index: -1;
      }

    .right-btn {
        position: fixed;
        right: 20px;
        bottom: 46px;
        z-index: 99999999999999999999999;
      }

      .financing-bar {
        background: linear-gradient(to right,#d10bcd, #f43ea8, #772dac);
        background-size: 400% 400%;
        -webkit-animation: WelcomePageGradient 10s ease infinite;
        -moz-animation: WelcomePageGradient 10s ease infinite;
        animation: WelcomePageGradient 10s ease infinite;
        color: white;
        vertical-align: middle;
        height: 45px;
      }


      .financing-bar p.smaller{
        text-align: center;
        font-size: 16px;
        padding: 7px;
      }

      .financing-bar a {
        border-bottom: .5px solid white;
        margin-left: 5px;
      }

      .bottom-course-nav {
        height: 100px;
        background: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 999999999;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
      }



    .l {
        display: block;
       float: left;
        vertical-align: top;
    }

    .r {
        display: block;
       
        vertical-align: top;

    }

    img.procedure-room {
        width: 90%;
        margin: 20px;
        filter: brightness(1.07);
      }

    .left-words {
        font-size: 20px;
        padding-top: 8px;
        text-transform: uppercase;
        padding-left: unset;
        padding-right: 250px;
        text-align: right;
        line-height: 1.2em;
      }
      
      .left-words-2 {
        font-size: 12px;;
      }
      

    img.model-img {
        position: relative;
        min-height: auto;
        width: 100%;
        border-radius: 20px;
        
    }

    .model-video-row {
        display: block;
        white-space: unset;
        overflow-y: scroll;
        height: auto;
        width: 100%;
        margin-left: 0%;
        padding-top: 10px;
        position: relative;
        text-align: center;
    }


    .main-model-video-container {
        display: inline-block;
        position: relative;
        height: auto;
        width: 18%;
        padding: 4px;
        margin: 3px;
        text-align: center;
        overflow: hidden;
      
    }

    .model-video-container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    .smaller {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 1.05em;
        width: 90%;
        margin: 0px auto;
        font-weight: 600;
        font-family: poppins, sans-serif;
    }

    .yes-box i {
        margin-left: 80px;
    }

   p.smaller  {
        font-size: .80em;
    }

 


    .best-of img{
        position: absolute;
        width: 8%;
        right: 10px;
        top: 10px;
        z-index: 999999;
    }


    .artist-box {
        width: 14.5%;
        margin: 2% .2%;
        padding: .2%;
        overflow: hidden;
        white-space: wrap;
        display: inline-block;
        position: relative;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        height: 16.18rem;
    }



    .main-video-container {
        width: 90%;
        height: 550px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
        text-align: center;
    }

    .video-container {
       width: 100%;
       text-align: center;
       margin: 0 auto;
   
       display: block;
       height: 550px;
       overflow: hidden;
       position: relative;
    }
    
    video {
        position: absolute;
        width: auto;
        max-width: 120%;
        height: fit-content;
        left: 50%; transform: translate(-50%, -50%);
        top: 40%; 
        margin: 0 auto;
        aspect-ratio: 16/9;
    }


.action-button2 {
    display: inline-block;
    position: relative;
    z-index: 900000;
    background-color: transparent;
    width: 125px;
    height: auto;
    margin: 0px auto;
    vertical-align: middle;
    font-size: 40px;
    color: white;
    padding: 0px;
    padding-top: 18px;
    padding-bottom: 22px;
    margin-top: 0px;
    margin-bottom: 3px;
    text-align: center;
    text-transform: uppercase;
    font-size: 9px;
    -webkit-appearance: none;
    transition: .4s;
      border-image: linear-gradient(to right, #005e7f, #61b6cd);
      border-radius: 50px;
      background-size: 100%;
      background: rgb(0, 0, 0);
      border: 2px transparent;
      border-radius: 30px;
      background-origin: border-box;
      background-clip: content-box, border-box;
      vertical-align: middle;
      font-family: "Sanz-Thin", sans-serif;
      margin-left: 12px;
      vertical-align: middle;
    }
    
    
    
    .action-button2::after {
    z-index: -100000;
    border-radius: 50px;
    content: '';
    display: block;    
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2.5px);
    height: calc(100% + 2px);
    transform: translateZ(-1px);
    background: linear-gradient(306deg, black, #772dac, #772dac, rgb(22, 22, 22), #f43ea8, #f43ea8);
    background-color: #f6f6f6;
    background-color: #f9fcff;
    
    background: #772dac;  /* fallback for old browsers */
    background: linear-gradient(306deg, black, #772dac, #772dac, #772dac, #f43ea8, #f43ea8, #f43ea8);
    background-size: 400% 400%;
    
    -webkit-animation: WelcomePageGradient 10s ease infinite;
    -moz-animation: WelcomePageGradient 10s ease infinite;
    animation: WelcomePageGradient 10s ease infinite;
    font-family: "Sanz-Thin", sans-serif;
    }
    
    
    .action-button-text2 {
    display: block;
    position: absolute;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: transparent;
    vertical-align: middle;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    font-weight: 700;
    letter-spacing: .8px;
    width: 100%;
    letter-spacing: 0;
    font-family: "Sanz-Heavy", sans-serif;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.27);
    font-size: 0.995rem;
    line-height: 1.15rem;
    letter-spacing: 0px;
    text-transform: none;
    }

    a.top-links {
        display: inline-block;
        position: relative;
        margin: 0 auto;
        text-align: center;
        font-weight: 800;
        background: linear-gradient(to left, #020202, #1a1a1a, #181818, #141414, #ffffff, #1a1a1a, #151515, #161616);
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background-size: 400% 400%;
        background-clip: text;
        -webkit-background-clip: text;
        letter-spacing: -1px;
        color: rgba(0, 0, 0, 0);
        text-transform: none;
        padding: .9rem;
        padding-top: 0;
        padding-bottom: 0;
        -webkit-animation: WelcomePageGradient 10s ease infinite;
        -moz-animation: WelcomePageGradient 10s ease infinite;
        animation: WelcomePageGradient 10s ease infinite;
        font-size: 1.075rem;
        font-size: 0.995rem;
        line-height: 1.15rem;
        letter-spacing: -.4px;
    }

    a.top-links2 {
        margin: 0 auto;
        font-weight: 700;
        font-size: .7em;
        font-family: "Sanz-Reg", sans-serif;
        margin-left: 0px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        letter-spacing: -1px;
        color: rgb(0, 0, 0);
        text-transform: none;
        padding: .6rem;
        padding-top: 0;
        display: inline-block;
        font-size: .845rem;
        line-height: 1.15rem;
        letter-spacing: -.4px;
        text-decoration: none;
        background: white;
        margin: 10px;
        margin-right: 4px;
        margin-left: 4px;
        border-radius: 4px;
        padding: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    }

    .title4.wht.c.studio {
        position: absolute;
    z-index: 99;
    font-family: "Sanz-Reg-Expd", sans-serif;
    color: white;
    font-weight: 300;
    font-size: 1.9em;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.492);
    letter-spacing: 1.5px;
    padding-right: 0px;
    top: 90%; transform: translate(0, -90%);
    padding-bottom: 0px;
    width: 100%;
    
    }

    .internal-button {
        padding: 14px;
        margin: 20px;
        width: 160px;
        margin: 20px auto;
        margin-bottom: 10px;
        background: rgb(255, 255, 255);
        border-radius: 50px;
    }

    a.internal-link {
        color: transparent;
        font-weight: 800;
        text-decoration: none;
        padding-top: 0px;
        font-size: 1.375rem;
        letter-spacing: -.1px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background:  #f43ea8;
        margin-left: 0px;
        letter-spacing: 0px;
        background-clip: text;
        -webkit-backgrund-clip: text;
        text-shadow: none;
    }


    .mobile-no {
        display: block;
    }

    .desktop-not {
        display: none;
    }

    .main-title {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   
        color: black;
        text-transform: uppercase;
        letter-spacing: .6px;
        font-size: 10.5px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 20px;
   
        background: white;
        padding-bottom: 10px;
        line-height: 1.4em;
        color: rgb(27, 27, 27);
    
    }

    .title2.wht.c.studio {
        text-align: center;
        position: relative;
        z-index: 9999;
        font-family: "Sanz-Reg-Expd", sans-serif;
        color: white;
        font-weight: 200;
        font-size: 36px;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.554);
        letter-spacing: 1.5px;
    }


    .service-row {
        text-align: center;
        padding-top: 20px;
        display: block;
        position: relative;
      
      }
      
      .service-container {
        display: inline-block;
        width: 190px;
        height: 280px;
        padding: unset; margin: 0;
        margin-left: 2.5px;
        margin-right: 2.5px;
        vertical-align: middle;
        text-align: center;
        position: relative;
        overflow: hidden;
        background: transparent;
       
      }


      video.rotate-180 {
        transform: rotate(-180deg);
      }

      .service-container-overlay {
        position: absolute;
        top: 50%; left: 50%; transform: translate(-50%, -50%);
        width: 100%; height: 280px;
        background: linear-gradient(160deg, transparent, transparent, rgba(197, 80, 255, 0.672));
        background: linear-gradient(160deg, transparent, transparent, rgba(13, 13, 13, 0.672));
        z-index: 9;
       
      }

      .service-container-overlay.eye {
        position: absolute;
        top: 21%; left: 50%; transform: translate(-50%, -50%);
        width: 100%; height: 95%;
        background: linear-gradient(200deg, transparent, transparent, rgba(197, 80, 255, 0.672));
        z-index: 9;
        background: linear-gradient(160deg, transparent, transparent, rgba(13, 13, 13, 0.672));
      }

      .service-name {
        color: white;
        position: absolute;
        top: 90%; left: 50%; transform: translate(-50%, -88%);
        z-index: 99;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.558);
        font-family: roboto-condensed, sans-serif;
      }

      .service-name.eye {
        top: 60%;
      }

      .rotate-180 {
        transform: rotate(180deg);
      }

      .eyeliner {
        left: 50px; top: 30px;
        transform: rotate(-45deg) ;
        margin-top: 5px;
        z-index: 999999;
      }

      .service-container img {
        width: 100%;
      }

      .left-container {
        display: inline-block;
        width: 50%;
        vertical-align: middle;
    }

    .right-container {
        display: inline-block;
        width: 50%;
        vertical-align: middle;
        margin: 30px auto;
    }


      .sibling {
        position: relative;
    
      }

      img.sibling-duo {
    
        height: auto;
        margin: 10px auto;
        mask-image: linear-gradient(to bottom right,transparent 0%,rgba(0, 0, 0, 1.0) 10%, rgba(0, 0, 0, 1.0) 100%);
        
      }

      .sibling-overlay {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
          
      
      }

      .sibling-duo.overlay-text {
        position: absolute;
        color: white;
        z-index: 999999;
        font-size: 18px;
        font-weight: 600;
        width: 45%;
        line-height: 1.6em;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.949);
        font-family: roboto-condensed, sans-serif;
   
      }

      .p.l {
        width: 100%;
        display: block;
    }

    .service-container video {
        display: block;
        position: relative;
        transform: translate(0);
        left: 0; top: 0;
        width: auto;
        height: 100%;
        vertical-align: top;
        margin: 0 auto;
        margin-top: unset;
        background: unset;
     
      }





}